import React from 'react';
import About from './components/About';
import Experience from './components/Experience';
import Education from './components/Education';
import Footer from './components/Footer';
import NavBar from './components/NavBar';

function App() {
  return (
    <div>
      {/* <NavBar /> */}
      <main className="pt-16 bg-[linear-gradient(180deg,_black_30%,_#111_50%,_#111_100%)]">
        <About />
        {/* <Experience />
        <Education /> */}
        {/* <Accomplishments /> */}
        {/* <Contact /> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
